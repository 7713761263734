/* dark theme variables */
$mainBackgroundDark: #12252f !important;
$buttonBackground: #e8eaf2 !important;
$buttonColor: #e8eaf2 !important;
$backgroundDark: #001e30 !important;
$backgroundLight: #e8eaf2 !important;
$mainColorLight2: #d6d6d6 !important;
$mainColorLight: #9fb8c5 !important;
$mainColorDark: #2e3c63 !important;
$mainColorDark2: #000000 !important;
$transparent: transparent !important;
$backgroundDark2: #333e49 !important;
$pureWhite: #ffffff !important;
$vivid1: #1b9fb7 !important;


// Dark theme
.T003 {
  // sets background color for whole application
  background: $mainBackgroundDark;
  filter: saturate(1.5);
  div,
  b,
  th,
  span {
    b {
      color: $mainColorLight2;
    }
    color: $mainColorLight;
  }

  //svgs
  svg {
    color: $backgroundDark2;
  }
  .MuiSvgIcon-root {
    color: $mainColorLight2;
  }

  //circular loader color
  .MuiCircularProgress-svg {
    color: $vivid1;
  }

  // tab indicators
  .MuiTabs-indicator {
    background: $vivid1;;
  }

  //input fields
  input,
  .MuiInputBase-root {
    background: $mainColorLight2;
    color: $mainColorDark2;
  }

  // react datepicker css
  .react-datepicker__month-container {
    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__current-month {
      color: $mainColorDark2;
    }
  }

  //select dropdowns and contents
  .MuiSelect-root,
  .MuiMenu-list {
    background: $mainColorLight2;
    color: $mainColorDark2;
  }

  //Logout link
  .MuiLink-root {
    color: $mainColorLight;
  }

  // css to make logo brighter specific to dark theme
  #header .logo-wrap img {
    // filter: brightness(1.6);
  }

  //css for paper and cards.
  .MuiPaper-root {
    // card properties
    background: $backgroundDark;
    color: $mainColorLight;
    border: 0.5px solid $mainColorLight;
  }

  //css specific to filter content
  .filterWrap {
    background: $backgroundDark;
  }

  //css specific to tables
  table {
    thead {
      tr {
        background: $backgroundDark2;
        text-shadow: none;
        th {
          background: $backgroundDark2;
          text-shadow: none;
          span {
          text-shadow: none;
          }
        }
      }
      
    }
    tbody {
      background: $backgroundDark;
      tr {
        background: $backgroundDark;
        td,
        .zui-sticky-col {
          text-shadow: none !important;
          background: $backgroundDark;
        }
      }
      td {
        background: $backgroundDark;
        color: $mainColorLight;
        .avatarGrey  {
          span {
            color: $mainColorLight2;
          }
        }
        span {
          color: $mainColorDark2;
          opacity: 1;
          text-shadow: none !important;
          font-weight: 500;
          filter: contrast(1.4);
        }
      }
    }

    tfoot {
      opacity: 1;
      tr,
      td,
      div {
        background: $backgroundDark2;
        color: $mainColorLight;
        div {
          background: $backgroundDark2;
          color: $mainColorLight;
        }
      }
    }

    //footer
    .MuiTableFooter-root {
      background: $backgroundDark2;
      color: $mainColorLight;
    }
  }

  //permissions css
  .settingsWrapper {
    .MuiPaper-root {
      .rectangle {
        background: $backgroundLight;
        .rectangleTitle {
          color: $mainColorDark2;
        }
      }
    }
  }

  //thank you screen css
  .card {
    background: $backgroundDark;
  }
  #supportTicket {
    .MuiGrid-root {
      .MuiBox-root {
        background: $backgroundDark;
        color: $mainColorLight;
        border: none;
      }
    }
  }

  // css for selected tabs
  button.Mui-selected {
    background: $backgroundDark;
  }

  //specific to taxation Form
  #taxation-form {
    background: $backgroundDark;
    div[id^="page"] {
      color: $mainColorDark;
      div,
      span {
        color: $mainColorDark;
      }
    }
    .MuiPaper-root {
      background: $transparent;
      color: $mainColorLight;
    }
    .MuiBox-root {
      background: $transparent;
      color: $mainColorLight;
    }
    .MuiButton-outlinedPrimary {
      border: 1px solid $mainColorLight;
    }
    .MuiButtonBase-root {
      color: $mainColorLight;
      background: $transparent;
    }
    .MuiButton-label {
      color: $mainColorLight;
    }
    .partSubheading {
      color: $mainColorLight;
      opacity: 1;
    }
  }

  //promotion collapse buttons
  .promotion-expanded-button-container {
    .MuiButtonBase-root {
      background: $mainColorLight;
      span {
        color: $mainColorDark2;
      }
    }
  }

  // promotion expanded item
  .promotion-expanded-item {
    span {
      color: $mainColorLight2;
    }
  }

  //button css
  .MuiButton-contained {
    background: $buttonBackground;
    color: $mainColorLight;
  }
  .clearBtn, .btnCancel, 
  .SupportTicket-backBtn-196 {
    background: $mainColorLight;
    border: 0.6px solid $mainColorLight2;
    span {
      color: $pureWhite;
    }
  }
  .save {
    span {
      color: $mainColorLight2
    }
  }
  .MuiButton-root {
    box-shadow: none !important;
    border: 0.6px solid $mainColorLight;
  }

  // reports save button.
  a,
  span {
    .btn,
    .save,
    .MuiButton-containedSizeSmall {
      background: $backgroundDark;
      span {
        color: $pureWhite;
      }
    }
  }

  // label css
  .MuiButton-label {
    color: $mainColorDark;
  }
  color: $mainColorLight;
}
